<template>
  <b-overlay :show="loading">
    <b-card-actions action-collapse title="Daftar Reward">
      <!-- ref="refreshCard"
    action-refresh
    @refresh="refreshStop('refreshCard')" -->
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-button v-if="allowCreate()" @click.prevent="add" variant="primary">
            <feather-icon icon="PlusIcon" class="mr-50" />
            Tambah
          </b-button>
          <b-modal v-if="allowCreate()" v-model="showModalTambah" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal" centered title="Form Tambah">
            <b-form>
              <b-form-group>
                <label for="reward">Foto Reward</label> <br />
                <input type="file" ref="file_foto" @change="onSelectedPhoto($event, index)" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Nama Reward</label>
                <b-form-input v-model="form.judul" id="reward" placeholder="Nama Reward" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Keterangan</label>
                <b-form-textarea v-model="form.keterangan" id="reward" placeholder="Keterangan" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Kuota</label>
                <b-form-input v-model="form.kouta" id="reward" placeholder="Kuota" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Nilai Poin</label>
                <b-form-input v-model="form.nilai" id="reward" placeholder="Poin" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Tampilkan ? </label>
                <b-form-checkbox class="ml-2" v-model="form.show" switch inline> </b-form-checkbox>
                <!-- <b-form-input v-model="form.nilai" id="reward" placeholder="Poin" /> -->
              </b-form-group>
              <b-form-group>
                <label for="reward">Tipe</label>
      <b-form-radio v-model="form.type" name="some-radios" value="affiliate">Affliate</b-form-radio>
      <b-form-radio v-model="form.type" name="some-radios" value="karyawan">Karyawan</b-form-radio>
      <b-form-radio v-model="form.type" name="some-radios" value="konsumen">Konsumen</b-form-radio>
              </b-form-group>
            </b-form>

            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button size="sm" class="mr-1" variant="primary" @click="submit"> Tambah </b-button>
                <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
              </section>
            </template>
          </b-modal>
          <b-modal v-if="allowUpdate()" v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary" ok-title="Ubah" cancel-title="Batal" centered title="Form Ubah">
            <b-form>
              <b-form-group>
                <label for="reward">Foto Reward</label> <br />
                <img v-if="form.photo" :src="form.patch" style="width: 70px" alt="" class="d-block mb-1" />
                <input type="file" ref="file_foto" @change="onSelectedPhoto($event, index)" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Nama Reward</label>
                <b-form-input v-model="form.judul" id="reward" placeholder="Nama Reward" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Keterangan</label>
                <b-form-textarea v-model="form.keterangan" id="reward" placeholder="Keterangan" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Kuota</label>
                <b-form-input v-model="form.kouta" id="reward" placeholder="Kuota" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Nilai Poin</label>
                <b-form-input v-model="form.nilai" id="reward" placeholder="Poin" />
              </b-form-group>
              <b-form-group>
                <label for="reward">Tampilkan ? </label>
                <b-form-checkbox class="ml-2" v-model="form.show" switch inline> </b-form-checkbox>
                <!-- <b-form-input v-model="form.nilai" id="reward" placeholder="Poin" /> -->
              </b-form-group>
              <b-form-group>
                <label for="reward">Tipe</label>
      <b-form-radio v-model="form.type" name="some-radios" value="affiliate">Affliate</b-form-radio>
      <b-form-radio v-model="form.type" name="some-radios" value="karyawan">Karyawan</b-form-radio>
      <b-form-radio v-model="form.type" name="some-radios" value="konsumen">Konsumen</b-form-radio>
              </b-form-group>
            </b-form>

            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button size="sm" class="mr-1" variant="info" @click="submit"> Ubah </b-button>
                <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
              </section>
            </template>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
            <b-input-group size="sm">
              <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>
            <template #cell(kouta)="row">
              <template class="text-center">
                {{ formatRupiah(row.value) }}
              </template>
            </template>
            <template #cell(nilai)="row">
              <template class="text-center">
                {{ formatRupiah(row.value) }}
              </template>
            </template>
            <template #cell(patch)="row">
              <strong class="text-center">
                <!-- <img :src="row.item.patch ? row.item.patch + row.item.photo : '-'" style="width: 70px" alt="" class="d-block mb-1" /> -->
                <img :src="row.value ? row.value : '-'" style="width: 70px" alt="" class="d-block mb-1" />
              </strong>
            </template>
            <template #cell(show)="row">
              <strong class="text-center">
                <b-badge variant="success" v-if="row.value == 1"> <feather-icon icon="CheckIcon"></feather-icon> </b-badge>
                <b-badge variant="danger" v-else> <feather-icon icon="XIcon"></feather-icon> </b-badge>
              </strong>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="allowUpdate()" v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1" variant="outline-info">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button v-if="allowDelete()" v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)" class="mr-1" variant="outline-danger">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>

            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-col>
        <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
          <pre>{{ infoModal.content }}</pre>
        </b-modal>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
        </b-col>
      </b-row>
    </b-card-actions>
  </b-overlay>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BFormRadio,BOverlay, BTable, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BFormTextarea, BFormCheckbox, BInputGroupAppend, BButton } from "bootstrap-vue";

export default {
  components: {
    BCardActions,BFormRadio,
    VBTooltip,
    ToastificationContent,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showModalTambah: false,
      showModalEdit: false,
      id: null,
      jenis_penyakit: null,
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      form: {
        photo: null,
        judul: null,
        keterangan: null,
        kouta: null,
        nilai: null,
        show: null,
        type: 'affiliate',
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "patch", label: "Foto Reward", sortable: true },
        { key: "judul", label: "Nama Reward", sortable: true },
        { key: "kouta", label: "Kuota", sortable: true },
        { key: "nilai", label: "Poin", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "show", label: "Tampilkan", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      loading: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.getData();
  },
  methods: {
    onSelectedPhoto(e, i) {
      const fileObj = e.target.files || e.dataTransfer.files;
      const file = fileObj[0];
      const images_ext = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
      if (!images_ext.includes(file.type)) {
        this.displayError({
          message: "Harap pilih file foto!",
        });
        return false;
      }
      this.form.photo = file;
    },
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalTambah = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Reward "${item.judul}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const fd = new FormData();
          fd.append("id", item.id);
          fd.append("fungsi", 1);
          this.$store
            .dispatch("reward/save", fd)
            .then(() => {
              this.pesanBerhasilHapus();
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        photo: null,
        judul: null,
        keterangan: null,
        kouta: null,
        nilai: null,
        show: null,
        type: 'affliate'
      };
      this.showModalTambah = false;
      this.showModalEdit = false;
    },
    async submit() {
      this.loading = true;
      if (this.form.photo == null || this.form.judul == null) {
        this.pesanGagalSimpan();
        this.loading = false;
        return false;
      }

      const fd = new FormData();
      if (this.id) {
        fd.append("id", this.id);
      }
      fd.append("photo", this.form.photo);
      fd.append("judul", this.form.judul);
      fd.append("keterangan", this.form.keterangan);
      fd.append("kouta", this.form.kouta);
      fd.append("nilai", this.form.nilai);
      fd.append("show", this.form.show == true ? 1 : 0);
      fd.append("type", this.form.type);

      if (this.form.photo != null) {
        try {
          await this.$store.dispatch("reward/save", fd);
          this.displaySuccess({
            message: "Data berhasil disimpan",
          });
          this.loading = false;
          this.resetForm();
          this.getData();
        } catch (e) {
          this.loading = false;
          this.resetForm();
          this.displayError(e);
          return false;
        }
      } else {
        this.displayError({
          message: "Photo tidak boleh kosong!",
        });
      }
    },
    edit(item) {
      this.id = item.id;
      this.form = item;
      this.showModalEdit = true;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      this.loading = true;
      this.$store
        .dispatch("reward/getData", {})
        .then(() => {
          this.loading = false;
          this.items = this.$store.state.reward.datas;
          console.log("this.items", this.items);
          this.totalRows = this.items.length;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
